import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  FooterWrapper,
  FooterCenter,
  Row,
  Col,
  RowInn,
  Title,
  Text,
  Button,
  Copy,
  ButtonLegal,
  ImageWrapper,
} from "./index.styled";
import Map from "icons/map.svg";
import Logo from "icons/logo_footer.svg";
import useWindowSize from "helpers/use-window-size";

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { type, title, text, email, copy },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          type
          title
          text
          email
          copy
        }
      }
    }
  `);

  const { width, isMobile } = useWindowSize();

  const registerYourInterestOnClick = () => {
    const header = document.querySelector("#header");
    const menu = document.querySelector("#menu");
    const inquire = document.querySelector("#inquire");
    const buttonMenu = document.querySelector(".button-menu");
    const buttonInquire = document.querySelector(".button-inquire");

    if (isMobile) {
      menu.classList.remove("open");
      buttonMenu.classList.remove("active");
    }

    inquire.classList.toggle("open");
    document.body.classList.toggle("hidden", inquire.classList.contains("open"));
    header.classList.toggle("open", inquire.classList.contains("open"));
    buttonInquire.classList.toggle("active", inquire.classList.contains("open"));
  };

  const legalOnClick = () => {
    const legal = document.querySelector("#legal");

    legal.classList.toggle("open");
    document.body.classList.toggle("hidden", legal.classList.contains("open"));
  };

  const replaceRegisteredSymbol = (title) => {
    return title.replace(/®/g, '<sup>®</sup>');
  };

  return (
    <FooterWrapper>
      <FooterCenter>
        <Row>
          <Col className="col-0">
            <RowInn>
              {title[0] && <Title dangerouslySetInnerHTML={{ __html: replaceRegisteredSymbol(title[0]) }} />}
              {text[0] && <Text>{text[0]}</Text>}
              <Button onClick={registerYourInterestOnClick}>REGISTER YOUR INTEREST</Button>
            </RowInn>
          </Col>
          <Col className="col-1">
            <RowInn>
              {title[1] && <Title>{title[1]}</Title>}
              {width > 768 && text[1] &&(
                <Text>{text[1]}</Text>
              )}
              {width <= 768 && text[2] &&(
                <Text>{text[2]}</Text>
              )}
            </RowInn>
            <RowInn>
              <img className="map" src={Map} alt="" />
            </RowInn>
          </Col>
          <Col className="col-2">
            <RowInn>
              {title[2] && <Title>{title[2]}</Title>}
              {email && (
                <Text>
                  <a href={`mailto:${email}`}>{email}</a>
                </Text>
              )}
            </RowInn>
            <RowInn>
              <img className="logo" src={Logo} alt="" />
              {copy[0] && <Copy>{copy[0]}</Copy>}
              <ButtonLegal onClick={legalOnClick}>LEGAL</ButtonLegal>
            </RowInn>
          </Col>
        </Row>
      </FooterCenter>
      <ImageWrapper id="footer-image" className={type ? type : ""}>
        {width > 768 && (
          <StaticImage src="../../images/home/footer.jpg" alt="" />
        )}
      </ImageWrapper>
    </FooterWrapper>
  );
};

export default Footer;
