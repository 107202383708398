import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";
import { HeaderCenterStyles } from "components/header/index.styled";
import { HeaderLogoStyles } from "components/header/index.styled";
import { ButtonStyles } from "components/header/index.styled";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  overflow-y: auto;
  z-index: 110;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  .menu {
    background: #ffffff;
  }
  ${mediaMax("mobile")} {
    opacity: 1;
    visibility: visible;
    transform: translateY(-100%);
    &.open {
      transform: translateY(0);
    }
  }
`;

export const Top = styled.div`
  ${HeaderCenterStyles}
  position: sticky !important;
  top: 0;
  width: calc(100% + 20px*2);
  margin: 0 -20px;
  padding: ${calculateResponsiveSize(38)} ${calculateResponsiveSize(40)};
  z-index: 4;
  &::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    background: #ffffff !important;
  }
  ${mediaMax("desktopSm")} {
    padding: ${calculateTabletResponsiveSize(38)} ${calculateTabletResponsiveSize(40)};
  }
`;

export const LegalLogo = styled.div`
  ${HeaderLogoStyles}
  margin: 0 auto 0 0;
  background: #000000;
`;

export const ButtonBack = styled.div`
  ${ButtonStyles}
  color: #000000;
  font-weight: normal;
  border-color: #000000;
  &:hover {
    color: #ffffff;
    background: #000000;
  }
`;

export const LegalContent = styled.div`
  max-width: ${calculateResponsiveSize(786)};
  margin: 0 auto;
  padding: ${calculateResponsiveSize(30)} 0 ${calculateResponsiveSize(100)};
  font-size: ${calculateResponsiveSize(14)};
  line-height: 1.6;
  strong {
    display: block;
    font-weight: 600;
    letter-spacing: ${calculateResponsiveSize(0.42)};
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0 0 0 ${calculateResponsiveSize(25)};
  }
  table{ 
    width: 100%;
    margin: 0 0 ${calculateResponsiveSize(10)} 0;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
  }
  th {
    width: 50%;
    padding: ${calculateResponsiveSize(10)} 0;
    text-align: left;
    font-weight: 600;
    letter-spacing: ${calculateResponsiveSize(0.42)};
    text-transform: uppercase;
    border-bottom: 1px solid #000000;
  }
  th:first-child {
    padding-right: ${calculateResponsiveSize(20)};
  }
  th:last-child {
    padding-left: ${calculateResponsiveSize(20)};
  }
  td {
    padding: ${calculateResponsiveSize(10)} 0;
    border-bottom: 1px solid #000000;
  }
  td:first-child {
    padding-right: ${calculateResponsiveSize(20)};
  }
  td:last-child {
    padding-left: ${calculateResponsiveSize(20)};
  }
  ${mediaMax("desktopSm")} {
    max-width: ${calculateTabletResponsiveSize(786)};
    padding: ${calculateTabletResponsiveSize(30)} 0 ${calculateTabletResponsiveSize(100)};
    font-size: ${calculateTabletResponsiveSize(14)};
    strong {
      letter-spacing: ${calculateTabletResponsiveSize(0.42)};
    }
    ul {
      padding: 0 0 0 ${calculateTabletResponsiveSize(25)};
    }
    table{ 
      margin: 0 0 ${calculateTabletResponsiveSize(10)} 0;
    }
    th {
      padding: ${calculateTabletResponsiveSize(10)} 0;
      letter-spacing: ${calculateTabletResponsiveSize(0.42)};
    }
    th:first-child {
      padding-right: ${calculateTabletResponsiveSize(20)};
    }
    th:last-child {
      padding-left: ${calculateTabletResponsiveSize(20)};
    }
    td {
      padding: ${calculateTabletResponsiveSize(10)} 0;
    }
    td:first-child {
      padding-right: ${calculateTabletResponsiveSize(20)};
    }
    td:last-child {
      padding-left: ${calculateTabletResponsiveSize(20)};
    }
  }
  ${mediaMax("mobile")} {
    max-width: 100%;
    padding: 30px 0 60px;
    font-size: 14px;
    strong {
      letter-spacing: 0.42px;
    }
    ul {
      padding: 0 0 0 25px;
    }
    table {
      margin: 0 0 10px 0;
    }
    th {
      padding: 10px 0;
      letter-spacing: 0.42px;
    }
    th:first-child {
      padding-right: 10px;
    }
    th:last-child {
      padding-left: 10px;
    }
    td {
      padding: 10px 0;
    }
    td:first-child {
      padding-right: 10px;
    }
    td:last-child {
      padding-left: 10px;
    }
  }
`;

export const Title = styled.h3`
  margin: 0 0 ${calculateResponsiveSize(50)} 0;
  text-align: center;
  font-size: ${calculateResponsiveSize(15)};
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: ${calculateResponsiveSize(0.6)};
  text-transform: uppercase;
  overflow: hidden;
  .hide & {
    opacity: 0;
    visibility: hidden;
  }
  ${mediaMax("desktopSm")} {
    margin: 0 0 ${calculateTabletResponsiveSize(50)} 0;
    font-size: ${calculateTabletResponsiveSize(15)};
    letter-spacing: ${calculateTabletResponsiveSize(0.6)};
  }
  ${mediaMax("mobile")} {
    margin: 0 0 30px 0;
    font-size: 15px;
    letter-spacing: 0.6px;
  }
`;

export const Cols = styled.div`
  display: flex;
  gap: ${calculateResponsiveSize(40)};
  padding: ${calculateResponsiveSize(10)} 0;
  position: relative;
  &::before, &::after {
    position: absolute;
    top: ${calculateResponsiveSize(10)};
    bottom: ${calculateResponsiveSize(10)};
    content: "";
    border-left: 1px solid #000000;
  }
  &::before {
    left: 33.33%;
  }
  &::after {
    right: 32%;
  }
  ${mediaMax("desktopSm")} {
    gap: ${calculateTabletResponsiveSize(40)};
    padding: ${calculateTabletResponsiveSize(10)} 0;
    &::before, &::after {
      top: ${calculateTabletResponsiveSize(10)};
      bottom: ${calculateTabletResponsiveSize(10)};
    }
  }
  ${mediaMax("mobile")} {
    flex-direction: column;
    gap: 0;
    padding: 0;
    &::before, &::after {
      display: none;
    }
  }
`;

export const Col = styled.div`
  width: 33.33%;
  ${mediaMax("mobile")} {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #000000;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    br {
      display: none;
    }
  }
`;
