import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";

export const MainIntroWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  height: 100svh;
  padding: 20px 50px;
  background: #fbf4ed;
  opacity: 1;
  animation: 2s ease 4s normal forwards 1 fadeOut;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  z-index: 150;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(10)};
  opacity: 0;
  animation: 1s ease 0.6s normal forwards 1 fadeIn;
  position: relative;
  z-index: 2;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  & > img {
    width: 100%;
  }
  ${mediaMax("desktopSm")} {
    gap: ${calculateTabletResponsiveSize(10)};
  }
  ${mediaMax("mobile")} {
    gap: 10px;
  }
`;

export const LogoLeftWrapper = styled.div`
  img {
    height: ${calculateResponsiveSize(48)};
  }
  ${mediaMax("desktopSm")} {
    img {
      height: ${calculateTabletResponsiveSize(48)};
    }
  }
  ${mediaMax("mobile")} {
    img {
      height: 30px;
    }
  }
`;

export const LogoRightWrapper = styled.div`
  width: 0;
  overflow: hidden;
  animation: 1.5s ease 1.6s normal forwards 1 slideIn;
  @keyframes slideIn {
    0% {
      width: 0;
    }
    100% {
      width: ${calculateResponsiveSize(344)};
    }
  }
  img {
    height: ${calculateResponsiveSize(30)};
  }
  ${mediaMax("desktopSm")} {
    @keyframes slideIn {
      0% {
        width: 0;
      }
      100% {
        width: ${calculateTabletResponsiveSize(344)};
      }
    }
    img {
      height: ${calculateTabletResponsiveSize(30)};
    }
  }
  ${mediaMax("mobile")} {
    @keyframes slideIn {
      0% {
        width: 0;
      }
      100% {
        width: 218px;
      }
    }
    img {
      height: 19px;
    }
  }
`;
