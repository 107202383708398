import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";
import Close from "icons/close.svg";

export const Wrap = styled.div`
  .cookies-banner {
    position: fixed;
    bottom: ${calculateResponsiveSize(20)};
    left: ${calculateResponsiveSize(122)};
    right: ${calculateResponsiveSize(122)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${calculateResponsiveSize(20)};
    padding: ${calculateResponsiveSize(18)} ${calculateResponsiveSize(20)};
    font-size: ${calculateResponsiveSize(14)};
    color: #ffffff;
    line-height: 1.4;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s;
    z-index: 1000;
  }
  &.show {
    .cookies-banner {
      opacity: 1;
      visibility: visible;
    }
  }
  .cookies-banner-accept-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${calculateResponsiveSize(76)};
    height: ${calculateResponsiveSize(25)};
    font-size: ${calculateResponsiveSize(10)};
    color: #ffffff;
    font-weight: 600;
    letter-spacing: ${calculateResponsiveSize(1)};
    text-transform: uppercase;
    border-radius: 6px;
    border: 1px solid #ffffff;
    background: transparent;
    transition: color 0.25s, border 0.25s, background 0.25s;
    position: relative;
    cursor: pointer;
    &:hover {
      color: #000000;
      background: #ffffff;
    }
  }
  ${mediaMax("desktopSm")} {
    .cookies-banner {
      bottom: ${calculateTabletResponsiveSize(20)};
      left: ${calculateTabletResponsiveSize(122)};
      right: ${calculateTabletResponsiveSize(122)};
      gap: ${calculateTabletResponsiveSize(20)};
      padding: ${calculateTabletResponsiveSize(18)} ${calculateTabletResponsiveSize(20)};
      font-size: ${calculateTabletResponsiveSize(14)};
    }
    .cookies-banner-accept-btn {
      width: ${calculateTabletResponsiveSize(76)};
      height: ${calculateTabletResponsiveSize(25)};
      font-size: ${calculateTabletResponsiveSize(10)};
      letter-spacing: ${calculateTabletResponsiveSize(1)};
    }
  }
  ${mediaMax("mobile")} {
    .cookies-banner {
      bottom: 0;
      left: 0;
      right: 0;
      gap: 10px;
      padding: 15px 20px;
      font-size: 14px;
      border-radius: 0;
    }
    .cookies-banner-accept-btn {
      width: 65px;
      height: 20px;
      font-size: 9px;
      letter-spacing: 0.9px;
    }
  }
`;

export const Content = styled.div`
`;

export const LinkItem = styled.a`
  text-decoration: underline;
  color: #ffffff;
  &:hover {
    text-decoration: none;
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: 50%;
  right: ${calculateResponsiveSize(15)};
  width: ${calculateResponsiveSize(26)};
  height: ${calculateResponsiveSize(26)};
  transform: translateY(-50%);
  background: url(${Close}) center center no-repeat;
  background-size: ${calculateResponsiveSize(24)};
  transition: background-size 0.25s;
  cursor: pointer;
  &:hover {
    background-size: ${calculateResponsiveSize(26)};
  }
  ${mediaMax("desktopSm")} {
    right: ${calculateTabletResponsiveSize(15)};
    width: ${calculateTabletResponsiveSize(26)};
    height: ${calculateTabletResponsiveSize(26)};
    background-size: ${calculateTabletResponsiveSize(24)};
    &:hover {
      background-size: ${calculateTabletResponsiveSize(26)};
    }
  }
  ${mediaMax("mobile")} {
    right: 15px;
    width: 20px;
    height: 20px;
    background-size: 18px;
    &:hover {
      background-size: 20px;
    }
  }
`;
