import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Input from "./input";

const SelectInput = ({
  className,
  name,
  defaultValue = "",
  rules = {},
  ...props
}) => {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      rules={rules}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            instanceId="selectbox"
            onChange={(val) => {
              clearErrors(name);
              onChange(val.value);
            }}
            className={className}
            value={props.options.find((opt) => opt.value === value) || ""}
            {...props}
            name={name}
            error={error}
            rules={rules}
          />
        </>
      )}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

export default SelectInput;
