import React, { useRef } from "react";
import Contact from "components/ui/contact";
import { Wrapper, Top, InquireLogo, ButtonBack } from "./index.styled";

const Inquire = () => {
  const inquireRef = useRef(null);

  const toDo = () => {
    const header = document.querySelector("#header");
    inquireRef.current.classList.remove("open");
    document.body.classList.toggle("hidden", inquireRef.current.classList.contains("open"));
    header.classList.toggle("open", inquireRef.current.classList.contains("open"));

    const activeHeaderElement = header.querySelector(".active");
    if (activeHeaderElement) {
      activeHeaderElement.classList.remove("active");
    }
  };

  const scrollToTop = () => {
    toDo();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onBack = () => {
    toDo();
  };

  return (
    <Wrapper id={"inquire"} ref={inquireRef}>
      <Top>
        <InquireLogo onClick={scrollToTop} />
        <ButtonBack onClick={onBack}>Back</ButtonBack>
      </Top>
      <Contact />
    </Wrapper>
  );
};

export default Inquire;
