import { createGlobalStyle } from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";

const GlobalStyle = createGlobalStyle`
  html, body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #000000;
    line-height: 1.25;
    background: #fbf4ed;
    min-width: 320px;
  }
  body{
    overflow-y: scroll;
  }
  body.hidden{
    overflow: hidden;
  }
  /* body.intro-hidden {
    height: 100vh !important;
  }
  body.intro-hidden .smooth-wrapper {
    height: 100vh;
    overflow: hidden;
  } */
  html, body, A, input, select, textarea{
    font-family: 'Graphik', Arial, Tahoma, Geneva, Kalimati, sans-serif;
  }
  A{
    text-decoration: none;
    color: #000000;
    transition: all 0.25s ease 0s;
    outline: none;
  }
  A:hover{
    text-decoration: underline;
  }
  input, select, textarea {
    vertical-align: top;
    outline: none;
  }
  textarea {
    resize: vertical;
  }
  *{ -webkit-tap-highlight-color: transparent; box-sizing: border-box; }
  img, object, iframe, svg{ border: 0; vertical-align: top; }
  svg path, svg circle, svg line{ transition: all 0.25s ease 0s; }
  /* ${mediaMax("mobileLandscape")} {
    body.landscape {
      max-height: 100%;
      overflow: hidden;
      visibility: hidden;
      background: #8c9ea3;
    }
  } */
  .fadeIn {
    opacity: 0;
    transition: opacity 1s ease 0.5s;
    &.visible {
      opacity: 1;
    }
  }
  .fadeInTitleFromBottom {
    transform: translateY(110%);
    transition: transform 1s ease 0.5s;
    &.visible {
      transform: translateY(0);
    }
  }
  .fadeInFromBottom {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 1s ease 0.5s, opacity 1s ease 0.5s;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .slideInFromTop {
    position: relative;
    &::before {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      content: "";
      background: #fbf4ed;
      transition: all 1s ease 0.5s;
      z-index: 5;
    }
    &.visible {
      &::before {
        top: 100%;
      }
    }
  }
  .slideInFromLeft {
    position: relative;
    &::before {
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      content: "";
      background: #fbf4ed;
      transition: left 1s ease 0.5s;
      z-index: 5;
    }
    &.visible {
      &::before {
        left: 100%;
      }
      &.about::before {
        opacity: 1;
      }
    }
    &.about {
      opacity: 0;
      transition: opacity 0s ease 1.5s;
      &::before {
        background: #ffffff;
        transition: left 1s ease 1.5s;
      }
      &.visible {
        opacity: 1;
        &::before {
          left: 100%;
        }
      }
    }
  }

  /* ----- UserWay ----- */
  body .uwy {
    opacity: 0;
    visibility: hidden;
    animation: 2s ease 5s normal forwards 1 fadeIn;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  body .uwy > .userway_buttons_wrapper  {
    top: auto !important;
    bottom: ${calculateResponsiveSize(30)} !important;
    left: ${calculateResponsiveSize(35)} !important;
    right: auto !important;
    transform: none !important;
  }
  body .uwy .uai, body .uwy .uai > span {
    width: ${calculateResponsiveSize(40)} !important;
    height: ${calculateResponsiveSize(40)} !important;
  }
  body .uwy .uai > span > img {
    width: 100% !important;
    height: auto !important;
  }
  ${mediaMax("desktopSm")} {
    body .uwy > .userway_buttons_wrapper  {
      bottom: ${calculateTabletResponsiveSize(30)} !important;
      left: ${calculateTabletResponsiveSize(35)} !important;
    }
    body .uwy .uai, body .uwy .uai > span {
      width: ${calculateTabletResponsiveSize(40)} !important;
      height: ${calculateTabletResponsiveSize(40)} !important;
    }
  }
  ${mediaMax("mobile")} {
    body .uwy > .userway_buttons_wrapper  {
      bottom: 9px !important;
      left: 14px !important;
    }
    body .uwy .uai, body .uwy .uai > span {
      width: 30px !important;
      height: 30px !important;
    }
  }

  /* ----- Registered Symbol ----- */
  sup {
    font-size: 0.4em;
    position: relative;
    top: 0.3em;
  }

  /* ----- Safari profile icon ----- */
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
`;

export default GlobalStyle;
