import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  &.error {
    color: #EF3E42;
  }
`;

export const InputField = styled.input`
  width: 100%;
  height: ${calculateResponsiveSize(26)};
  padding: 0;
  font-size: ${calculateResponsiveSize(12)};
  color: #000000;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #000000;
  background: none;
  outline: none;
  &::placeholder {
    color: #000000;
    opacity: 1;
  }
  &.error {
    border-color: #EF3E42;
  }
  ${mediaMax("desktopSm")} {
    height: ${calculateTabletResponsiveSize(26)};
    font-size: ${calculateTabletResponsiveSize(12)};
  }
  ${mediaMax("mobile")} {
    height: 26px;
    font-size: 12px;
  }
`;
