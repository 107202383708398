import React from "react";
import CookieConsent from "react-cookie-consent";
import useWindowSize from "helpers/use-window-size";

import { Wrap, Content, LinkItem, ButtonClose } from "./index.styled";

const CookiesBanner = () => {
  const ref = React.useRef();
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    setTimeout(() => {
      ref?.current?.classList?.add("show");
    }, 8000);
  }, []);

  const onClose = () => {
    ref?.current?.classList?.remove("show");
  };

  return (
    <Wrap ref={ref}>
      <CookieConsent
        location={"none"}
        buttonText={"ALLOW"}
        disableStyles={true}
        containerClasses="cookies-banner"
        buttonClasses="cookies-banner-accept-btn"
        disableButtonStyles={true}
        buttonWrapperClasses={"cookies-banner-btns"}
        contentClasses={"cookies-banner-content"}
        expires={150}
        //debug={true}
      >
        <Content>
          {isMobile ? (
            <>
              This website uses <LinkItem href="https://www.cookiesandyou.com" target="_blank">cookies</LinkItem>.
            </>
          ) : (
            <>
              This website uses cookies to ensure you get the best experience on our website. <LinkItem href="https://www.cookiesandyou.com" target="_blank">Learn more</LinkItem>.
            </>
          )}
        </Content>
        <ButtonClose onClick={onClose} />
      </CookieConsent>
    </Wrap>
  );
};
export default CookiesBanner;
