import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${calculateResponsiveSize(6)};
  cursor: pointer;
  ${mediaMax("desktopSm")} {
    gap: ${calculateTabletResponsiveSize(6)};
  }
  ${mediaMax("mobile")} {
    gap: 6px;
  }
`;

export const Title = styled.div``;

export const GroupItem = styled.div`
  display: inline-block;
  margin-right: ${calculateResponsiveSize(12)};
  ${mediaMax("desktopSm")} {
    margin-right: ${calculateTabletResponsiveSize(12)};
  }
  ${mediaMax("mobile")} {
    margin-right: 12px;
  }
`;

export const InputWrap = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  overflow: hidden;
  visibility: hidden;
`;

export const Wrap = styled.div`
  display: flex;
  &.checked {
    ${Input}:checked + span {
      &::after {
        content: "";
        position: absolute;
        top: ${calculateResponsiveSize(4)};
        bottom: ${calculateResponsiveSize(4)};
        left: ${calculateResponsiveSize(4)};
        right: ${calculateResponsiveSize(4)};
        border-radius: 50%;
        background: #000000;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    content: "";
    width: ${calculateResponsiveSize(16)};
    height: ${calculateResponsiveSize(16)};
    border: solid 1px #000000;
    border-radius: 50%;
    box-sizing: border-box;
  }
  ${mediaMax("desktopSm")} {
    &.checked {
      ${Input}:checked + span {
        &::after {
          top: ${calculateTabletResponsiveSize(4)};
          bottom: ${calculateTabletResponsiveSize(4)};
          left: ${calculateTabletResponsiveSize(4)};
          right: ${calculateTabletResponsiveSize(4)};
        }
      }
    }
    span {
      width: ${calculateTabletResponsiveSize(16)};
      height: ${calculateTabletResponsiveSize(16)};
    }
  }
  ${mediaMax("mobile")} {
    &.checked {
      ${Input}:checked + span {
        &::after {
          top: 4px;
          bottom: 4px;
          left: 4px;
          right: 4px;
        }
      }
    }
    span {
      width: 16px;
      height: 16px;
    }
  }
`;
