import React, { useRef } from "react";
import { Wrapper, List, ListItem, Item } from "./index.styled";
import { links } from "../links";

const Menu = () => {
  const menuRef = useRef(null);

  const handleItemClick = (url) => {
    const header = document.querySelector("#header");
    menuRef.current.classList.remove("open");
    document.body.classList.toggle("hidden", menuRef.current.classList.contains("open"));
    header.classList.toggle("open", menuRef.current.classList.contains("open"));

    const activeHeaderElement = header.querySelector(".active");
    if (activeHeaderElement) {
      activeHeaderElement.classList.remove("active");
    }

    const timeout = setTimeout(() => {
      const targetElement = document.querySelector(`${url.substring(url.indexOf("#"))}-section`);

      if (targetElement) {
        const offsetTop = targetElement.offsetTop;
        window.scrollTo({
          top: offsetTop,
          // behavior: "smooth",
        });
      }
    }, 100);

    window.location.href = url;

    return () => clearTimeout(timeout);
  };

  return (
    <Wrapper id={"menu"} ref={menuRef}>
      <List>
        {links.map((item, index) => {
          return (
            <ListItem key={index}>
              <Item onClick={() => handleItemClick(item.url)}>{item.text}</Item>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default Menu;
