import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "components/ui/form";
import TextInput from "components/ui/text-input";
import SelectInput from "components/ui/select";
import RadioInput from "components/ui/radio-input";
import {
  ContactWrapper,
  ContactInn,
  Title,
  FormContent,
  Row,
  Radios,
  Text,
  Info,
  ButtonWrapper,
  Button,
  ThanksMessage,
  ThanksMessageTitle,
  ThanksMessageDescription,
  ButtonBack,
} from "./index.styled";
import {
  selectPurchasingOrRenting,
  selectHearAbout,
  selectBedroom,
} from "./types";

const Contact = () => {
  const form = useForm();
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    formState: { errors },
  } = form;

  const onSubmit = useCallback((input) => {
    setSubmitting(true);
    fetch("/form-submission", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    })
      .then(() => {
        setIsSubmit(true);
      })
      .catch((error) => {
        alert(error.message);
      });
  });

  const onBack = () => {
    form.reset();
    setIsSubmit(false);
    setSubmitting(false);
  };

  return (
    <ContactWrapper id={"contact-form"}>
      <ContactInn className={isSubmit ? "hide" : "show"}>
        <Title>INQUIRE</Title>
        <Form onSubmit={onSubmit} formProps={form} className="contact-form">
          <FormContent>
            <Row className="row-0">
              <SelectInput
                name={"purchasingOrRenting"}
                title={"Are you interested in purchasing or renting?"}
                placeholder={"Select"}
                options={selectPurchasingOrRenting}
              />
              <Info className={Object.keys(errors).length > 0 ? "error" : ""}>*Required Fields</Info>
            </Row>
            <Row>
              <TextInput
                name={"firstName"}
                title={"First Name"}
                required
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z]+(?:-[a-zA-Z]+)?(?: [a-zA-Z]+)?$/,
                }}
                maxLength={255}
              />
              <TextInput
                name={"lastName"}
                title={"Last Name"}
                required
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z]+(?:-[a-zA-Z]+)?(?: [a-zA-Z]+)?$/,
                }}
                maxLength={255}
              />
            </Row>
            <Row>
              <TextInput
                name={"email"}
                title={"Email"}
                type="email"
                required
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,50})+$/,
                }}
              />
              <TextInput
                name={"phone"}
                title={"Phone"}
                required
                rules={{
                  required: true,
                  pattern: /^(\+?\d{1,3}[- .]?)?(\(?\d{3}\)?[- .]?)?\d{3}[- .]?\d{4}$/,
                }}
                maxLength={18}
              />
            </Row>
            <Row>
              <TextInput
                name={"zip"}
                title={"Zip Code"}
                rules={{
                  pattern: /^\d{5}(?:[-\s]?\d{4})?$/,
                }}
                maxLength={10}
              />
              <SelectInput
                name={"hearAbout"}
                title={"How did you hear about us?"}
                placeholder={"Select"}
                options={selectHearAbout}
              />
            </Row>
            <Row>
              <SelectInput
                name={"bedroom"}
                title={"Desired number of bedrooms?"}
                placeholder={"Select"}
                options={selectBedroom}
                required
                rules={{ required: true }}
              />
              <Radios>
                <Text>Are you an agent?</Text>
                <RadioInput
                  options={{ yes: "Yes", no: "No" }}
                  name={"broker"}
                  className={"contact-radio"}
                />
              </Radios>
            </Row>
            <Row>
              <TextInput name={"notes"} title={"Comments"} />
            </Row>
            <ButtonWrapper>
              <Button type="submit" disabled={submitting}>{submitting ? "Sending..." : "Submit"}</Button>
            </ButtonWrapper>
          </FormContent>
        </Form>
      </ContactInn>
      <ThanksMessage className={isSubmit ? "show" : ""}>
        <ThanksMessageTitle>Thank you for registering.</ThanksMessageTitle>
        <ThanksMessageDescription>Your submission has been received, and you’ve been added to our priority list. You’ll be among the first to receive exclusive updates and information about residential opportunities at Optima McDowell Mountain™.</ThanksMessageDescription>
        <ButtonBack onClick={onBack}>Back</ButtonBack>
      </ThanksMessage>
    </ContactWrapper>
  );
};

export default Contact;
