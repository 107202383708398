import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import Input from "./input";

const TextInput = ({
  title = "",
  type = "text",
  name,
  defaultValue = "",
  rules = {},
  ...props
}) => {
  const { control, clearErrors } = useFormContext();
  return (
    <Controller
      rules={rules}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            className={props.className}
            onChange={(val) => {
              clearErrors(name);
              onChange(val);
            }}
            value={value}
            error={error}
            title={title}
            type={type}
            {...props}
          />
        </>
      )}
      name={name}
      defaultValue={defaultValue}
    />
  );
};

export default TextInput;
