import styled from "styled-components";
import { calculateResponsiveSize, calculateTabletResponsiveSize } from "helpers/calculate-responsive-size";
import { mediaMax } from "helpers/media-queries";
import { HeaderCenterStyles } from "components/header/index.styled";
import { HeaderLogoStyles } from "components/header/index.styled";
import { ButtonStyles } from "components/header/index.styled";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
  z-index: 110;
  &.open {
    opacity: 1;
    visibility: visible;
  }
  #contact-form {
    width: 100%;
    max-height: 100%;
    background: transparent;
    overflow-y: auto;
  }
  .menu {
    background: #ffffff;
  }
  ${mediaMax("mobile")} {
    opacity: 1;
    visibility: visible;
    transform: translateY(-100%);
    z-index: 98;
    &.open {
      transform: translateY(0);
    }
  }
`;

export const Top = styled.div`
  ${HeaderCenterStyles}
  position: absolute;
  top: 0;
  bottom: auto !important;
  padding: ${calculateResponsiveSize(38)} ${calculateResponsiveSize(40)};
  z-index: 4;
  &::before {
    top: 0;
    background: rgba(255, 255, 255, 0.1);
  }
  ${mediaMax("desktopSm")} {
    padding: ${calculateTabletResponsiveSize(38)} ${calculateTabletResponsiveSize(40)};
  }
  ${mediaMax("mobile")} {
    display: none;
  }
`;

export const InquireLogo = styled.div`
  ${HeaderLogoStyles}
  margin: 0 auto 0 0;
  background: #000000;
`;

export const ButtonBack = styled.div`
  ${ButtonStyles}
  color: #000000;
  font-weight: normal;
  border-color: #000000;
  &:hover {
    color: #ffffff;
    background: #000000;
  }
`;
