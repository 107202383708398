import React from "react";
import { Wrapper, Title, InputField } from "./index.styled";

const Input = ({
  className = "",
  title,
  type,
  error = false || null,
  value,
  textareaHeight,
  required = false,
  ...props
}) => {
  return (
    <Wrapper className={className}>
      {title && (
        <Title className={error && "error"}>
          {title}
          {required ? "*" : ""}
        </Title>
      )}
      <div style={{ height: `${textareaHeight}px` }}>
        {type === "textarea" ? (
          <>
            <textarea {...props} value={value} autoComplete="off" />
          </>
        ) : (
          <InputField
            {...props}
            value={value}
            className={error && "error"}
            autoComplete="off"
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Input;
