import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 20px;
  background: #ffffff;
  transform: translateY(-100%);
  transition: all 1s ease;
  z-index: 99;
  &.open {
    transform: translateY(0);
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  text-align: center;
  font-family: "Didot";
  font-size: 40px;
  color: #000000;
  line-height: 1.2;
  letter-spacing: -1.2px;
`;

export const Item = styled.div`
  cursor: pointer;
`;
